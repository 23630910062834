import { CarrelloInfoProdotto } from "./infoProdotto";

export interface RigaCarrelloInfo {
	datiCarrello: RigaCarrelloComplementari;
	datiProdotto: CarrelloInfoProdotto;
	messaggi: string[];
}

export class RigaCarrello {
	itemID: number;
	userID: string;
	riga: number;
	distributoreID: number;
	pacchettoID: number;
	inizioViaggio: Date;
	fineViaggio: Date;
	statoID: number;
	nazionalitaID: number;
	numeroPartecipanti: number;
	tesseraSingola: boolean | null;
	adeguatezza: boolean | null;
	codiceRUI: string;
	imageFilePartecipanti: string;
	elencoPartecipanti: string;
	importoViaggio: number;
	secondoImporto: number;
	numeroAlloggi: number | null;
	codicePromo: string;
	note: string;
	riferimentoPratica: string;
	prodottiCollegati: string;
	infoTotali: string;
	istanteInserimento: string | null;
	partecipanti: string;
	ulterioriDati: string;
}



export interface RigaCarrelloComplementari {
	dati: RigaCarrello;
	complementari: CarrelloInfoProdotto[];
}